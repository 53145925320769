import moment from 'moment';
export const propertyServices = {
  editProperty,
  getProperty,
  getPropertiesList,
  getCachedPropertiesList,
  getLandlordListingsTotal,
  getLandlordIntegrations,
  uploadPropertyPhotos,
  uploadRoomPhotos,
  sectionDetails,
  uploadRoomDetails,
  parselCal,
  propertyFinished,
  addRoomToExistingProperty,
  disableProperty,
  enableProperty,
  deleteProperty,
  disableRoom,
  enableRoom,
  deleteRoom,
  updateRent,
  updateRentMonths,
  updateAvailableFrom,
  confirmAvailability,
  askVerification,
  addressCoords,
  generateDateRents,
  getPricesInterval,
  getRentsInterval,
  generateStudentUrl,
  updateRoomDetails,
  getResidents,
  saveResident,
  deleteResident,
  enableBookingWindow
};

import {get, patch, post } from "./index.js";

async function editProperty(fields,popertyId){
  try {
    return await patch(`/property/edit/${popertyId}`,{...fields})
  } catch (e) {
    throw e;
  }
}

async function getProperty(property_id){
  try {
    return await get(`/property/get/${property_id}`)
  } catch (e) {
    throw e;
  }
}

let cachedProperties = null;
let fetchingProperties = false;
let fetchingPropertiesPromises = [];
async function getCachedPropertiesList(){
  if(cachedProperties){
    return cachedProperties;
  } else if(fetchingProperties) {
    return new Promise(resolve => {
      fetchingPropertiesPromises.push(resolve);
    });
  }
  try {
    fetchingProperties = true;
    cachedProperties = (await getPropertiesList(0, null, 10000)).properties;
    fetchingProperties = false;
    fetchingPropertiesPromises.forEach(resolve => resolve(cachedProperties));
    return cachedProperties;
  } catch (e) {
    throw e;
  }
}

async function getLandlordListingsTotal(){
  try{
    return await get(`/property/landlordListingsTotal`)
  }catch (e){
    throw e;
  }
}

async function getLandlordIntegrations(page, filters, size) {
    try {
        return await get(`/property/landlordIntegrations`, {page, filters, size});
    } catch (e) {
        throw e;
    }
}

async function getPropertiesList(page, filters, size, ignoreManaging = true){
  try {
    let response = await get(`/property/list/`, {page, filters, size, "ignoreManaging": ignoreManaging});
    cachedProperties = response.properties;
    return response;
  } catch (e) {
    throw e;
  }
}

async function uploadPropertyPhotos(photos,propertyId,setLoadingPercentage) {
  try {
    return await post(`/property/upload/propertyPhotos/${propertyId}`, photos ,setLoadingPercentage);
  } catch (e) {
    throw e;
  }
}

async function sectionDetails(details,propertyId) {
  try {
    return await post(`/property/sectionDetails/${propertyId}`, details );
  } catch (e) {
    throw e;
  }
}



async function uploadRoomPhotos(photos,propertyId,setLoadingPercentage) {
  try {
    return await post(`/property/upload/roomPhotos/${propertyId}`, photos ,setLoadingPercentage);
  } catch (e) {
    throw e;
  }
}

async function uploadRoomDetails(fields,propertyId,roomId){
  try {
    return await post(`/property/roomDetails/${propertyId}/${roomId}`, fields );
  } catch (e) {
    throw e;
  }
}

export async function updateRoomDetails(fields, propertyId, roomId){
  try {
    return await post(`/property/updateRoomDetails/${propertyId}/${roomId}`, fields);
  } catch (e) {
    throw e;
  }
}

async function parselCal(fields){
  try{
    return await post(`/property/parseICal`,fields)
  }catch (e){
    throw e;
  }
}

async function propertyFinished(fields, propertyId){
  try{
    return await post(`/property/finish/${propertyId}`,fields)
  }catch (e){
    throw e;
  }
}

async function addRoomToExistingProperty(propertyId){
  try{
    return await post(`/property/addRoom/${propertyId}`,{})
  }catch (e){
    throw e;
  }
}

async function disableProperty(propertyId){
  try{
    return await post(`/property/disable/${propertyId}`,{})
  }catch (e){
    throw e;
  }
}

async function enableProperty(propertyId){
  try{
    return await post(`/property/enable/${propertyId}`,{})
  }catch (e){
    throw e;
  }
}

async function deleteProperty(propertyId){
  try{
    return await post(`/property/delete/${propertyId}`,{})
  }catch (e){
    throw e;
  }
}

async function disableRoom(propertyId,roomId){
  try{
    return await post(`/property/disableRoom/${propertyId}/room_${roomId}`,{})
  }catch (e){
    throw e;
  }
}

async function enableRoom(propertyId,roomId){
  try{
    return await post(`/property/enableRoom/${propertyId}/room_${roomId}`,{})
  }catch (e){
    throw e;
  }
}

async function deleteRoom(propertyId,roomId){
  try{
    return await post(`/property/deleteRoom/${propertyId}/room_${roomId}`,{})
  }catch (e){
    throw e;
  }
}


async function getResidents(propertyId, roomId) {
  return get(`/property/getResidents/${propertyId}/${roomId}`);
}

async function saveResident(propertyId, roomId, resident) {
    return post(`/property/saveResident/${propertyId}/${roomId}`, resident);
}

async function deleteResident(propertyId, roomId, residentId) {
    return post(`/property/deleteResident/${propertyId}/${roomId}/${residentId}`);
}

async function updateRent(propertyId,roomId,rent){
  try{
    return await post(`/property/updateRent/${propertyId}/room_${roomId}`, {rent})
  }catch (e){
    throw e;
  }
}

async function updateRentMonths(propertyId,roomId,rentMonths){
  try{
    return await post(`/property/updateRentMonths/${propertyId}/room_${roomId}`, {rentMonths})
  }catch (e){
    throw e;
  }
}


async function updateAvailableFrom(propertyId,roomId,availableFrom){
  try{
    return await post(`/property/updateAvailableFrom/${propertyId}/room_${roomId}`, {availableFrom})
  }catch (e){
    throw e;
  }
}

async function confirmAvailability(propertyId,roomId){
  try{
    let url = "/property/confirmAvailability/" + propertyId;
    if(roomId) url += ("/room_" + roomId);
    return await post(url)
  }catch (e){
    throw e;
  }
}

async function askVerification(property_id) {
  try {
    return await post("/property/askVerification/" + property_id);
  } catch (e) {
    console.error("Error asking for verification", e);
  }
}

async function addressCoords(address, region) {
  try {
    return await get(`/property/addressCoords`, {address, region});
  } catch (e) {
    throw e;
  }
}

const  roundToFixed = (num, places) => {
  let mult = Math.pow(10,2);
  return (Math.round(num * mult) / mult).toFixed(places);
}

function generateDateRents(property, moveIn, moveOut, extraTenantsValue, discount) {
  let dateRents = [], dateRentsPt = [], total = 0;
  if (!property) return [];
  let {incompleteRentType: rentType} = property;

  let moveInDate = new Date(moveIn);
  let moveOutDate = new Date(moveOut);
  let moveInMoment = moment(moveIn).startOf("day");
  let moveOutMoment = moment(moveOut).endOf("day");

  let diff;
  if(rentType === 'monthly') {
    if(moveOutMoment.date() === moveInMoment.date()) {
      diff = moveOutMoment.diff(moveInMoment, 'month');
    } else {
      diff = moveOutMoment.clone().endOf("month").diff(moveInMoment.clone().startOf("month"), 'month');
    }
  } else {
    diff = moveOutMoment.clone().date(moveInMoment.date()).diff(moveInMoment, 'month');
  }

  for (let m = 0; m <= diff; m++) {
    let mo = moment().year(moveInMoment.year()).month(m + moveInDate.getMonth());
    let calcRent;
    if(discount){
      calcRent = property.room.fixedRent ?
          parseInt(property.room.rent + extraTenantsValue ) - (property.room.rent * discount)
          : parseInt(property.room.rentMonths[mo.locale('en').format("MMMM").toLowerCase()] + extraTenantsValue)
          - parseInt(property.room.rentMonths[mo.locale('en').format("MMMM").toLowerCase()]) * discount;
    }else{
      calcRent = parseInt(property.room.fixedRent ?
          (property.room.rent + extraTenantsValue ) :
          (property.room.rentMonths[mo.locale('en').format("MMMM").toLowerCase()] + extraTenantsValue));
    }
    if (rentType) {
      if ((m === 0 || m === diff) && rentType !== 'monthly') {
        if (rentType === 'daily') {
          const dailyPrice = calcRent / mo.daysInMonth();
          if (m === 0) {
            if(moveInMoment.date() > 1) {
              let endOfMonth = moveInMoment.clone().endOf('month');
              calcRent = dailyPrice * (endOfMonth.diff(moveInMoment, 'days') + 1);
            }
          } else {
            let beginningOfMonth = moveOutMoment.clone().startOf('month');
            calcRent = dailyPrice * (moveOutMoment.diff(beginningOfMonth, 'days') + 1);
          }
        } else {
          let moveInHalf = moveInDate.getDate() >= 15;
          let moveOutHalf = moveOutDate.getDate() <= 15;
          if ((m === 0 && moveInHalf) || (m === diff && moveOutHalf)) {
            calcRent = Math.round(calcRent * 100 / 2) / 100;
          }
        }
      }
    }
    total += calcRent;
    dateRents.push({
      rent: "€" + (dateRents.length === 0 ? '0.00' : Math.round(calcRent.toFixed(2)*100)/100),
      month: mo.locale("en").format("MMMM"),
      year: mo.locale("en").format("YYYY")
    });
    dateRentsPt.push({
      rent: "€" + (dateRentsPt.length === 0 ? '0.00' : calcRent.toFixed(2).replace(/\.00$/, '')),
      month: mo.locale("pt").format("MMMM"),
      year: mo.locale("pt").format("YYYY")
    });
  }
  return {dateRents, dateRentsPt, total: Math.round(total*100)/100};
}

function getPricesInterval (room, property, ignore_parsed = false) {
  let currentRoom = room;
  if(!currentRoom.fixedRent && !currentRoom.rentMonths) {
    ignore_parsed = false;
  }
  if (currentRoom.minRent && currentRoom.maxRent && !ignore_parsed) {
    return (currentRoom.minRent === currentRoom.maxRent ? currentRoom.minRent : currentRoom.minRent + '-' + currentRoom.maxRent) + '€';
  }
  if (!currentRoom.fixedRent && currentRoom?.rentMonths && Array.isArray(currentRoom.rentMonths)) {
    let minRent, maxRent;
    minRent = maxRent = currentRoom.rentMonths[0];
    currentRoom.rentMonths.forEach(rent => {
      if (rent < minRent) minRent = rent;
      if (rent > maxRent) maxRent = rent;
    })
    if (minRent === maxRent) return minRent + '€';
    return minRent + ' - ' + maxRent + '€';
  }
  if (!currentRoom.fixedRent && currentRoom?.rentMonths && Object.keys(currentRoom?.rentMonths).length > 0) {
    let minRent, maxRent;
    minRent = maxRent = currentRoom.rentMonths['january'];
    Object.keys(currentRoom.rentMonths).forEach(month => {
      if (currentRoom.rentMonths[month] < minRent) minRent = currentRoom.rentMonths[month]
      if (currentRoom.rentMonths[month] > maxRent) maxRent = currentRoom.rentMonths[month]
    })
    if (minRent === maxRent) return minRent + '€';
    return minRent + ' - ' + maxRent + '€';
  } else if (currentRoom?.fixedRent || (property.migrated && currentRoom?.rent)) {
    return currentRoom.rent + '€';
  } else {
    return ('tbd');
  }
}

function getRentsInterval (property) {
  let min;
  let max;
  let minRoomRent;
  let maxRoomRent;
  let rooms = property.rooms;
  for(let room in rooms) {
    let roomRents = getPricesInterval(rooms[room], property)?.split('-');
    if(roomRents.length > 1){
      minRoomRent = parseInt(roomRents[0].trim());
      maxRoomRent = parseInt(roomRents[1].replace(/\D/g, "").trim())
    } else if(roomRents[0] !== "tbd") {
      minRoomRent = maxRoomRent = parseInt(roomRents[0].replace(/\D/g, "").trim());
    }
    if (!max || maxRoomRent > max) max = maxRoomRent;
    if (!min || minRoomRent < min) min = minRoomRent;
  }
  if(!min && !max) return "";
  if (min === max) return min + '€';
  return min + ' - ' + max + '€';
}

function generateStudentUrl(property_id, room_id) {
  return process.env.STUDENT_BASE_URL + "/house/" + property_id + (room_id ? ("/" + room_id) : "");
}

async function enableBookingWindow(property_id, bookingWindow) {
  try {
    return await post("/property/enableBookingWindow/" + property_id, {bookingWindow});
  } catch (e) {
    console.error("Error asking for verification", e);
  }
}
